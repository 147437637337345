'use strict';

import $ from 'jquery';
import React from 'react';
import _ from 'underscore';

import Ajax from '../jskit/general/Ajax';
import Formatter from '../jskit/general/Formatter';
import Utils from '../jskit/general/Utils';
import ReactUtils from '../jskit/react/ReactUtils';
import DateRangePicker from '../jskit/react/forms/DateRangePicker';
import {Select2Widget} from '../jskit/react/forms/Select2';
import TransactionUtils from '../transactions/TransactionUtils';
import DonutChart from './DonutChart.jsx';
import LineChart from './LineChart.jsx';

function removeLinksFromMarkdown(text) {
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)\.?/g; // Regular expression to match link syntax
  return text.replace(linkRegex, ''); // Replace all links with their text content
}

export default class PagespeedReport extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.datePickerRef = React.createRef();
    this.shareButtonRef = React.createRef();
    this.perfMetricsRef = React.createRef();
    this.histMetricsRef = React.createRef();

    let activeTab = 'tab-summary';
    if (window.location.hash) {
      activeTab = window.location.hash.substr(1);
    }

    this.state = {
      data: null,
      dataURL: null,
      monitoringServer: null,
      dataError: null,
      shareURL: null,
      resultId: null,
      activeTab: activeTab,

      //analysis
      analysis: null,

      //history
      historyLoading: false,
      history: null,
      historyPage: 1,
      historyHasNextPage: false,
      historyHasPrevPage: false,

      //metrics
      metrics: null,
      series: null,
      metricsStartDate: null,
      metricsEndDate: null,
      metricsAggregation: 'MED',
      metricsSelected: 'tbt',
    };
  }

  handleAfterPrint() {
    this.setState({
      activeTab: this.savedTab || 'tab-summary',
    });
  }

  componentDidMount() {
    if (this.props.query != null) {
      this.loadAllData();
    }
    window.addEventListener('beforeprint', this.handleBeforePrint);
    window.addEventListener('afterprint', this.handleAfterPrint);
  }

  componentDidUpdate() {
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip();

    $('.audit-header').click(function (e) {
      $($(e.currentTarget).data('target')).collapse('toggle');
    });
  }

  loadAllData() {
    this.loadReportData();
    this.loadReportHistory();
  }

  loadReportData() {
    new Ajax().post({
      url: this.props.pagespeedReportDataURL,
      data: this.props.query,
      encoder: 'json',
      decoder: 'json',
      success: function (response) {
        this.setState(
          {
            monitoringServer: response.monitoring_server,
            dataError: response.data_error,
            shareURL: response.share_url,
            resultId: response.result_id,
          },
          () => {
            this.loadReportMetrics();
          }
        );
        if (response.data_url) {
          this.loadResultFromURL(response.data_url);
        } else {
          this.setState(
            {
              data: Object.assign({}, response.data),
            },
            () => {
              this.calculateAnalysisChartsData();
              if (this.props.onDataLoaded) {
                this.props.onDataLoaded();
              }
            }
          );
        }
      }.bind(this),
    });
  }

  loadResultFromURL(result_url) {
    new Ajax().get({
      url: result_url,
      encoder: 'json',
      decoder: null,
      success: function (response) {
        try {
          response = JSON.parse(response);
          this.setState(
            {
              data: Object.assign({}, response),
            },
            () => {
              this.calculateAnalysisChartsData();
              if (this.props.onDataLoaded) {
                this.props.onDataLoaded();
              }
            }
          );
        } catch (e) {
          this.setState({
            data: {},
            dataError: 'Error loading report data: ' + e.message,
          });
        }
      }.bind(this),
    });
  }

  loadReportHistory() {
    if (!this.props.checkId) {
      return;
    }

    this.setState({
      historyLoading: true,
    });

    new Ajax().post({
      url: this.props.pagespeedReportHistoryURL,
      data: {
        check_id: this.props.checkId,
        page: this.state.historyPage,
      },
      encoder: 'json',
      decoder: 'json',
      success: function (response) {
        this.setState({
          history: response.results,
          historyLoading: false,
          historyPage: response.page,
          historyHasNextPage: response.has_next_page,
          historyHasPrevPage: response.has_prev_page,
        });
      }.bind(this),
    });
  }

  loadReportMetrics() {
    if (!this.props.checkId) {
      return;
    }

    let curDateRange = [null, null];
    if (this.datePickerRef.current) {
      curDateRange = this.datePickerRef.current.getCurrentDateRange();
    }

    new Ajax().post({
      url: this.props.pagespeedReportMetricsURL,
      data: {
        check_id: this.props.checkId,
        result_id: this.state.resultId,
        start_date: curDateRange[0] && curDateRange[0].toISOString(),
        end_date: curDateRange[1] && curDateRange[1].toISOString(),
        aggregation: this.state.metricsAggregation,
        metrics: [this.state.metricsSelected, 'performance_score', 'best_practices_score'],
      },
      encoder: 'json',
      decoder: 'json',
      success: function (response) {
        this.setState({
          metrics: response.metrics,
          series: response.series,
        });
      }.bind(this),
    });
  }

  isRunTestReport() {
    return !!this.props.query.task_id || !!this.props.query.file;
  }

  onDateRangeChange(start, end) {
    this.setState(
      {
        metricsStartDate: start,
        metricsEndDate: end,
      },
      this.loadReportMetrics.bind(this)
    );
  }

  onAggregationChange(e) {
    this.setState({metricsAggregation: e.target.value}, this.loadReportMetrics.bind(this));
  }

  onMetricsChange(e) {
    this.setState({metricsSelected: e.target.value}, this.loadReportMetrics.bind(this));
  }

  render() {
    if (!this.state.data) {
      return (
        <div className="p-5 text-center">
          <i className="fas fa-spinner fa-spin"></i> Loading report data...
        </div>
      );
    }
    if (this.state.dataError) {
      return (
        <div className="p-5 text-center">
          <div className="alert alert-danger">Error: {this.state.dataError}</div>
        </div>
      );
    }
    const pagespeed = this.state.data.pagespeed;
    if (!pagespeed) {
      return <div className="p-5 text-center">Report is not available yet. Please check back later.</div>;
    }
    var steps = [];
    if (pagespeed.steps.length == 1) {
      steps.push(this.renderResult(pagespeed.steps[0].lhr));
    } else {
      for (let i = 0; i < pagespeed.steps.length; i++) {
        steps.push(
          <div key={ReactUtils.Key.get(`card${i}`)} className="card">
            <div key={`step${i}_hdr`} className="card-header">
              <a
                className={ReactUtils.cssClass('btn btn-link', {collapsed: i > 0})}
                data-toggle="collapse"
                data-target={`#step${i}`}
              >
                {pagespeed.steps[i].name}
              </a>
            </div>
            <div
              key={ReactUtils.Key.get(`step${i}`)}
              id={`step${i}`}
              className={ReactUtils.cssClass('collapse', {show: i == 0})}
              data-parent="#steps"
            >
              <div className="card-body">{this.renderResult(pagespeed.steps[i].lhr)}</div>
            </div>
          </div>
        );
      }
    }
    /* Leaving here in case we decide to show the title block again
     * var check_title_block = null;
     * if (this.props.checkName) {
     *   check_title_block = (
     *     <div key="title-block" className="row">
     *       <div className="col">
     *         <div className="heading-container d-block d-lg-flex">
     *           <h1 className="heading">
     *             <span className="text-muted">Pagespeed report for:</span> {this.props.checkName}
     *           </h1>
     *         </div>
     *       </div>
     *     </div>
     *   );
     * } */

    return (
      <div className="pagespeed-report my-3 my-lg-5">
        <div className="pagespeed-steps">{steps}</div>
      </div>
    );
  }

  getCategoryScore(lhr, category) {
    const cat = lhr.categories[category];
    return Math.round(cat.score * 100);
  }

  handleDownloadPDF() {
    // open page print dialog
    this.savedTab = this.state.activeTab;
    this.setState({activeTab: null}, () => {
      // print page after 300ms so chart have time to render
      setTimeout(() => {
        window.print();
      }, 300);
    });
  }

  prepareAuditData(lhr, auditId) {
    const auditData = lhr.audits[auditId];
    auditData.impacting = [];

    // add category and related Core Web Vitals metrics
    for (const catId in lhr.categories) {
      const cat = lhr.categories[catId];
      for (const auditRef of cat.auditRefs) {
        if (auditRef.id == auditId) {
          auditData.category = catId;
          auditData.categoryTitle = cat.title;
          auditData.weight = auditRef.weight;
        }
        if (auditRef.relevantAudits) {
          for (const relAuditId of auditRef.relevantAudits) {
            if (relAuditId == auditId) {
              auditData.impacting.push(auditRef.acronym);
            }
          }
        }
      }
    }
    return auditData;
  }

  handleTabChange(e) {
    e.preventDefault();
    const tab = e.target.getAttribute('href').substr(1);
    this.setState({activeTab: tab});
  }

  renderResult(lhr) {
    return (
      <React.Fragment key={ReactUtils.Key.get()}>
        {this.renderReportHeader(lhr)}
        {this.renderErrors(lhr)}
        {this.renderWarnings(lhr)}
        <div key="main" className="row mb-5 ml-0 mr-0 pb-3 pt-4 pl-2 rounded-8 bg-white">
          <div className="col-md-6">
            {this.renderCheckParamsBlock(lhr)}
            <div className="row">
              <div className="col-md-6">{this.renderUptimeGradeBlock(lhr)}</div>
              <div className="col-md-6">{this.renderCWVBlock(lhr)}</div>
            </div>
            {this.state.shareURL && (
              <div className="my-3">
                <strong>Report Public URL: </strong>
                {this.state.shareURL}
              </div>
            )}
            {!this.isRunTestReport() && (
              <div className="d-flex align-items-center flex-wrap mb-3 report-buttons d-print-none">
                {this.renderShareButton()}
                <button className="flex-fill btn btn-outline-primary" onClick={this.handleDownloadPDF}>
                  Download PDF
                </button>
                {!this.props.query.check_id ? (
                  <a
                    className="flex-fill btn btn-outline-primary"
                    href={this.props.userAuthenticated ? this.props.createCheckURL : this.props.signUpURL}
                  >
                    Setup Alerts
                  </a>
                ) : (
                  <a className="flex-fill btn btn-outline-primary" href={this.props.editCheckURL}>
                    Edit Check
                  </a>
                )}
              </div>
            )}
          </div>
          <div className="col-md-6">{this.renderScreenshot(lhr)}</div>
        </div>
        <div className="report-body p-1 pb-4 rounded-8">
          <nav className="m-1 nav nav-pills nav-fill d-none d-sm-flex d-print-none">
            <a
              className={ReactUtils.cssClass('nav-item nav-link', {active: this.state.activeTab == 'tab-summary'})}
              href="#tab-summary"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={this.handleTabChange}
            >
              Summary
            </a>
            <a
              className={ReactUtils.cssClass('nav-item nav-link', {active: this.state.activeTab == 'tab-performance'})}
              href="#tab-performance"
              role="tab"
              aria-controls="tab-performance"
              aria-selected="false"
              onClick={this.handleTabChange}
            >
              Performance
            </a>
            <a
              className={ReactUtils.cssClass('nav-item nav-link', {
                active: this.state.activeTab == 'tab-structure',
              })}
              href="#tab-structure"
              role="tab"
              aria-controls="tab-structure"
              aria-selected="false"
              onClick={this.handleTabChange}
            >
              Audits
            </a>
            <a
              className={ReactUtils.cssClass('nav-item nav-link', {active: this.state.activeTab == 'tab-waterfall'})}
              href="#tab-waterfall"
              role="tab"
              aria-controls="tab-waterfall"
              aria-selected="false"
              onClick={this.handleTabChange}
            >
              Waterfall
            </a>
            <a
              className={ReactUtils.cssClass('nav-item nav-link', {active: this.state.activeTab == 'tab-history'})}
              href="#tab-history"
              role="tab"
              aria-controls="tab-history"
              aria-selected="false"
              onClick={this.handleTabChange}
            >
              History
            </a>
          </nav>
          <select
            className="form-control d-sm-none"
            onChange={(e) => this.setState({activeTab: e.target.value})}
            defaultValue="tab-summary"
          >
            <option value="tab-summary">Summary</option>
            <option value="tab-performance">Performance</option>
            <option value="tab-structure">Audits</option>
            <option value="tab-waterfall">Waterfall</option>
            <option value="tab-history">History</option>
          </select>

          <div className="tab-content px-4">
            <div
              id="tab-summary"
              className={ReactUtils.cssClass('tab-pane fade d-print-block', {
                'show active': this.state.activeTab == 'tab-summary' || !this.state.activeTab,
              })}
            >
              {this.renderSummaryTab(lhr)}
            </div>
            <div
              id="tab-performance"
              className={ReactUtils.cssClass('tab-pane fade d-print-block', {
                'show active': this.state.activeTab == 'tab-performance' || !this.state.activeTab,
              })}
            >
              {this.renderPerformanceTab(lhr)}
            </div>
            <div
              id="tab-structure"
              className={ReactUtils.cssClass('tab-pane fade d-print-block', {
                'show active': this.state.activeTab == 'tab-structure' || !this.state.activeTab,
              })}
            >
              {this.renderStructureTab(lhr)}
            </div>
            <div
              id="tab-waterfall"
              className={ReactUtils.cssClass('tab-pane fade d-print-block', {
                'show active': this.state.activeTab == 'tab-waterfall' || !this.state.activeTab,
              })}
            >
              {this.renderWaterfallTab()}
            </div>
            <div
              id="tab-history"
              className={ReactUtils.cssClass('tab-pane fade d-print-block', {
                'show active': this.state.activeTab == 'tab-history' || !this.state.activeTab,
              })}
            >
              {this.renderHistoryTab()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  copySharedURLToClipboard() {
    navigator.clipboard.writeText(this.state.shareURL);

    const label = 'Copy Public URL';
    // Change the button text to 'Copied!'
    this.shareButtonRef.current.textContent = 'Copied!';

    // Reset button text to 'Share Report' after 2 seconds
    setTimeout(() => {
      this.shareButtonRef.current.textContent = label;
    }, 2000);
  }

  handleShareReportClick(e) {
    e.preventDefault();
    new Ajax().post({
      url: this.props.pagespeedReportShareURL,
      data: this.props.query,
      encoder: 'json',
      decoder: 'json',
      success: function (response) {
        this.setState({
          shareURL: response.url,
        });
      }.bind(this),
    });
  }

  renderShareButton() {
    if (this.state.shareURL) {
      // return a button to copy shared URL
      return (
        <button className="flex-fill btn btn-primary" ref={this.shareButtonRef} onClick={this.copySharedURLToClipboard}>
          Copy Public URL
        </button>
      );
    } else {
      return (
        <button className="flex-fill btn btn-primary" onClick={this.handleShareReportClick}>
          Share Check Analysis
        </button>
      );
    }
  }

  renderReportHeader(lhr) {
    const url = lhr.requestedUrl || lhr.finalDisplayedUrl;
    return (
      <React.Fragment>
        <div key="report-title" className="row mb-3">
          <div className="col">
            <h2 className="mb-0">
              Performance report{' '}
              {url && (
                <React.Fragment>
                  for: <span className="text-brand-green">{url}</span>
                </React.Fragment>
              )}
            </h2>
            {this.props.checkName && (
              <div className="mb-0 fw-normal text-muted font-20">Check name: {this.props.checkName}</div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderCheckParamsBlock(lhr) {
    return (
      <div className="d-flex flex-wrap mb-5 gap-10">
        <div className="flex-fill metric d-flex align-items-top">
          <i className="icon mr-2 fa-solid fa-calendar-clock"></i>
          <div>
            <span className="label">Report Generated</span>
            <span className="value value-sm">{Formatter.shortDateTime(lhr.fetchTime)}</span>
          </div>
        </div>
        <div className="flex-fill d-flex align-items-top metric">
          <i className="icon mr-2 fa-solid fa-location-dot"></i>
          <div>
            <span className="label">Test Server Location</span>
            <span className="value value-sm">{this.state.monitoringServer.location}</span>
          </div>
        </div>
        <div className="flex-fill metric d-flex align-items-top">
          <i className="icon mr-2 fa-solid fa-laptop-mobile"></i>
          <div>
            <span className="label">Using</span>
            <span className="value value-sm">
              {this.state.data.options
                ? this.props.choices.emulated_device[this.state.data.options.settings.pagespeed_emulated_device]
                : 'Chrome'}
            </span>
          </div>
        </div>

        {this.state.data.options &&
          this.state.data.options.settings.pagespeed_connection_throttling != 'UNTHROTTLED' && (
            <div className="flex-fill metric d-flex align-items-center">
              <i className="icon mr-2 fa-solid fa-wifi"></i>
              <div>
                <span className="label">Connection</span>
                <span className="value value-sm">
                  {this.state.data.options &&
                    this.props.choices.connection_throttling[
                      this.state.data.options.settings.pagespeed_connection_throttling
                    ]}
                </span>
              </div>
            </div>
          )}
      </div>
    );
  }

  renderUptimeGradeBlock(lhr) {
    const performance_grade = this.state.data.pagespeed.uptimecom
      ? this.state.data.pagespeed.uptimecom.uptime_grade
      : '-';
    return (
      <div className="white-block mb-5 p-3 shadow-none grade-block">
        <div className="d-flex mb-3">
          <div className="flex-grow-1">
            <strong>Uptime Grade</strong>
          </div>
          <a className="text-muted d-print-none" href="#">
            <i
              data-toggle="tooltip"
              title="Your Uptime Grade is a comprehensive evaluation of your page’s performance, encompassing both its loading speed for users and its optimization for optimal performance."
              className="fas fa-info-circle ml-2"
            ></i>
          </a>
        </div>
        <div className="d-flex align-items-center gap-10">
          <div>
            <div className={`uptime-grade uptime-grade-${performance_grade}`}>{performance_grade}</div>
          </div>
          <div className="metric flex-fill d-flex align-items-center">
            <div>
              <div className="label">Performance</div>
              <div className="value">{this.getCategoryScore(lhr, 'performance')}%</div>
            </div>
          </div>
          <div className="metric flex-fill d-flex align-items-center">
            <div>
              <div className="label">Structure</div>
              <div className="value">{this.getCategoryScore(lhr, 'best-practices')}%</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCWVBlock(lhr) {
    return (
      <div className="white-block mb-5 p-3 shadow-none grade-block">
        <div className="d-flex mb-3">
          <div className="flex-grow-1">
            <strong>Core Web Vitals</strong>
          </div>
          <a className="text-muted d-print-none" href="#">
            <i
              data-toggle="tooltip"
              title="Web Vitals are a concise set of essential metrics that serve as key indicators of the speed and overall user experience your website provides to visitors, commonly referred to as a “delightful” experience."
              className="fas fa-info-circle ml-2"
            ></i>
          </a>
        </div>
        <div className="d-flex align-items-center gap-10">
          <div className="metric flex-fill d-flex align-items-center">
            <div>
              <div className="label">LCP</div>
              <div className="value">{this.getAuditDisplay(lhr, 'largest-contentful-paint')}</div>
            </div>
          </div>
          <div className="metric flex-fill d-flex align-items-center">
            <div>
              <div className="label">TBT</div>
              <div className="value">{this.getAuditDisplay(lhr, 'total-blocking-time')}</div>
            </div>
          </div>
          <div className="metric flex-fill d-flex align-items-center">
            <div>
              <div className="label">CLS</div>
              <div className="value">{this.getAuditDisplay(lhr, 'cumulative-layout-shift')}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSummaryTab(lhr) {
    var opportunities = _.sortBy(
      _.map(
        _.filter(
          lhr.categories.performance.auditRefs,
          (ref) =>
            lhr.audits[ref.id].details &&
            lhr.audits[ref.id].details.type === 'opportunity' &&
            lhr.audits[ref.id].details.overallSavingsMs > 0
        ),
        (ref) => this.prepareAuditData(lhr, ref.id)
      ),
      (x) => -x.details.overallSavingsMs
    );

    return (
      <React.Fragment>
        {this.renderFilmstrip(lhr)}
        {opportunities.length > 0 && this.renderAuditGroup('Top Issues', opportunities)}
      </React.Fragment>
    );
  }

  renderPerformanceTab(lhr) {
    return (
      <React.Fragment>
        <h3>Performance Metrics</h3>
        {this.renderPerformanceMetrics(lhr)}
        {this.renderInformativeMetrics(lhr)}
        <h3>Analysis</h3>
        {this.renderAnalysis()}
      </React.Fragment>
    );
  }

  renderStructureTab(lhr) {
    return (
      <React.Fragment>
        {this.renderCategoryGroup(lhr, 'Performance', 'performance')}
        {this.renderCategoryGroup(lhr, 'Guidelines', 'best-practices')}
        {this.renderCategoryGroup(lhr, 'Accessibility', 'accessibility')}
        {this.renderCategoryGroup(lhr, 'SEO', 'seo')}
        {this.renderCategoryGroup(lhr, 'Progressive Web Apps', 'pwa')}
      </React.Fragment>
    );
  }

  renderWaterfallTab() {
    const htmlString = TransactionUtils.renderWaterfallHTML(this.state.data.network);
    return (
      <React.Fragment>
        <h3>Requests waterfall</h3>
        <div dangerouslySetInnerHTML={{__html: htmlString}} />
      </React.Fragment>
    );
  }

  renderHistoryTab() {
    const m = _.find(this.state.metrics, (x) => x.name == this.state.metricsSelected);

    return (
      <React.Fragment>
        {this.props.checkId ? (
          <React.Fragment>
            <div className="my-3 d-flex align-items-center metrics-toolbar">
              <DateRangePicker
                ref={this.datePickerRef}
                timezone={window.TIMEZONE}
                openDirection="right"
                nullDateLabel="Last 7 days"
                enabledDateRanges="3h,12h,24h,day0,day1,7d,30d,mon0,mon1,yr0,yr1"
                startDate={this.state.metricsStartDate}
                endDate={this.state.metricsEndDate}
                onChange={this.onDateRangeChange}
              />
              <div className="aggregation-container">
                <Select2Widget
                  fieldName="aggregation"
                  onChange={this.onAggregationChange}
                  value={this.state.metricsAggregation}
                  choices={this.props.choices.aggregation}
                  titleText="Default aggregation type to use in reports"
                />
              </div>
            </div>

            <div className="white-block p-3 mb-3">
              <h3>Result Score Time Trend</h3>
              <div className="metrics-graph">
                <LineChart
                  ref={this.perfMetricsRef}
                  series={_.pick(this.state.series, ['performance_score', 'best_practices_score'])}
                  labels={[
                    _.find(this.state.metrics, (x) => x.name == 'performance_score'),
                    _.find(this.state.metrics, (x) => x.name == 'best_practices_score'),
                  ]}
                />
              </div>
            </div>
            <div className="white-block p-3 mb-3">
              <h3>{m && m.label} Time Trend</h3>
              {m && <p>{m.description}</p>}
              <div className="my-3 d-flex align-items-center metrics-toolbar">
                <div className="metric-select-container">
                  <Select2Widget
                    fieldName="metric"
                    onChange={this.onMetricsChange}
                    value={this.state.metricsSelected}
                    choices={_.filter(this.props.choices.metrics, (x) => {
                      return !_.contains(['performance_score', 'best_practices_score'], x[0]);
                    })}
                    titleText="Select metric to display"
                  />
                </div>
              </div>
              <div className="metrics-graph">
                <LineChart
                  ref={this.histMetricsRef}
                  series={_.pick(this.state.series, [this.state.metricsSelected])}
                  labels={[_.find(this.state.metrics, (x) => x.name == this.state.metricsSelected)]}
                />
              </div>
            </div>
            <div className="white-block p-3">
              <h3>Report History</h3>
              {this.renderReportHistory()}
            </div>
          </React.Fragment>
        ) : (
          this.renderHistoryNotAvailable()
        )}
      </React.Fragment>
    );
  }

  renderWarnings(lhr) {
    if (lhr.runWarnings.length > 0) {
      return (
        <div key="warnings" className="mb-3 text-light" role="alert">
          <ul className="list-unstyled">
            {lhr.runWarnings.map((warning, i) => (
              <li key={`warning${i}`}>{warning}</li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  }

  renderErrors(lhr) {
    if (lhr.runtimeError) {
      return (
        <div key="error-msg" className="alert alert-danger" role="alert">
          {lhr.runtimeError.message}
        </div>
      );
    }
  }

  renderScreenshot(lhr) {
    if (lhr.audits['final-screenshot'] && lhr.audits['final-screenshot'].details) {
      return (
        <div key="screenshot" className="pagespeed-screenshot">
          <img className="img-fluid" src={lhr.audits['final-screenshot'].details.data} />
        </div>
      );
    } else if (lhr.fullPageScreenshot && lhr.fullPageScreenshot.screenshot) {
      return (
        <div key="screenshot" className="pagespeed-screenshot">
          <img className="img-fluid" src={lhr.fullPageScreenshot.screenshot.data} />
        </div>
      );
    } else if (lhr.audits['full-page-screenshot'] && lhr.audits['full-page-screenshot'].details) {
      // deprecated: only used for old reports, delete branch after 2024-01-01
      return (
        <div key="screenshot" className="pagespeed-screenshot">
          <img className="img-fluid" src={lhr.audits['full-page-screenshot'].details.screenshot.data} />
        </div>
      );
    }
    return null;
  }

  filterAuditRefs(auditRefs) {
    // exludes audits that are metrics
    return auditRefs.filter((auditRef) => auditRef.group !== 'metrics' && auditRef.group !== 'hidden');
  }

  renderCategoryGroup(lhr, title, key) {
    if (lhr.categories[key]) {
      const c = lhr.categories[key];
      let audits = _.map(this.filterAuditRefs(lhr.categories[key].auditRefs), (x) => this.prepareAuditData(lhr, x.id));
      // hide audits that we don't want to show with score == 1
      audits = audits.filter((audit) => audit.score !== 1);
      // hide audits with score null and no details
      audits = audits.filter((audit) => !(audit.score === null && !audit.details));
      // sort audits by score showing audits with null score at the end
      audits = _.sortBy(audits, (audit) => (audit.score === null ? 2 : audit.score));
      return (
        <React.Fragment>
          <h3>
            {title} - {(c.score * 100).toFixed(0)}% {this.renderScoreBadge({score: c.score})}
          </h3>
          {this.renderAuditGroup('', audits)}
        </React.Fragment>
      );
    }
    return null;
  }

  getAuditDisplay(lhr, id) {
    var audit = lhr.audits[id];
    if (audit) {
      return audit.displayValue;
    }
    return 'n/a';
  }

  renderFilmstrip(lhr) {
    if (lhr.audits['screenshot-thumbnails'] && lhr.audits['screenshot-thumbnails'].details) {
      var frames = _.map(lhr.audits['screenshot-thumbnails'].details.items, (i) => (
        <div className="frame" key={'t' + i.timing}>
          <img src={i.data} title={i.timing + 'ms'} />
          <span>{(i.timing / 1000).toFixed(1) + 's'}</span>
        </div>
      ));
      return (
        <div key="vis" className="mb-5">
          <h3 className="my-4">Speed Visualization</h3>
          <div className="pagespeed-filmstrip">{frames}</div>
        </div>
      );
    }
    return null;
  }

  renderAuditGroup(title, audits) {
    var items = _.map(audits, (i) => this.renderAudit(i));
    return (
      <div key={title}>
        {title != '' && <h4>{title}</h4>}
        <div>{items}</div>
      </div>
    );
  }

  renderDetailsValue(val, valueType) {
    // all types of details specified here: https://github.com/GoogleChrome/lighthouse/blob/master/types/lhr/audit-details.d.ts
    if (val === undefined) {
      return '';
    }
    if (valueType === 'text') {
      return <React.Fragment>{val}</React.Fragment>;
    } else if (valueType === 'numeric' && typeof val === 'number') {
      //check if val is float
      if (!Number.isInteger(val)) {
        return <React.Fragment>{val.toFixed(2)}</React.Fragment>;
      } else {
        return <React.Fragment>{val}</React.Fragment>;
      }
    } else if (valueType === 'ms') {
      if (typeof val === 'number') {
        return <React.Fragment>{val.toFixed(2)}ms</React.Fragment>;
      } else {
        return <React.Fragment>{val}ms</React.Fragment>;
      }
    } else if (valueType === 'code') {
      if (typeof val === 'string') {
        return (
          <React.Fragment>
            <code>{val}</code>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <code>{this.renderDetailsValue(val, val.type)}</code>
          </React.Fragment>
        );
      }
    } else if (valueType === 'source-location') {
      return (
        <React.Fragment>
          {val.url}:{val.line}:{val.column}
        </React.Fragment>
      );
    } else if (valueType === 'multi') {
      return <React.Fragment>{_.map(val.items, (i) => this.renderDetailsValue(i))}</React.Fragment>;
    } else if (valueType === 'thumbnail') {
      return (
        <React.Fragment>
          <img src={val.data} />
        </React.Fragment>
      );
    } else if (valueType === 'link') {
      return (
        <a target="_blank" href={val.url}>
          {val.text}
        </a>
      );
    } else if (valueType === 'url') {
      return (
        <React.Fragment>
          {val} [
          <a target="_blank" href={val}>
            open
          </a>
          ]
        </React.Fragment>
      );
    } else if (valueType === 'timespanMs') {
      return Formatter.formatMs(val);
    } else if (valueType === 'bytes') {
      return Formatter.humanBytes(val);
    } else if (valueType === 'node') {
      return (
        <React.Fragment>
          {val.nodeLabel} <code title={val.selector}>{val.snippet}</code>
        </React.Fragment>
      );
    }

    return <React.Fragment>{val.toString()}</React.Fragment>;
  }

  renderDetailsTable(headings, items, subheadings) {
    const isSubitems = subheadings === undefined;
    const hdrs = _.map(
      _.filter(headings, (h) => h),
      (h) => <th key={h.key}>{h.text}</th>
    );
    const rows = [];
    for (let i = 0; i < items.length; i++) {
      const cells = [];
      for (let j = 0; j < headings.length; j++) {
        if (headings[j] == null) {
          continue;
        }
        const key = headings[j].key;
        const type = headings[j].type;
        const val = items[i][key];
        cells.push(<td key={`${i}_${j}_${key}`}>{this.renderDetailsValue(val, type)}</td>);
      }
      rows.push(
        <tr key={`row${i}`} className={isSubitems ? 'subitem' : ''}>
          {cells}
        </tr>
      );
      if (items[i].subItems) {
        rows.push(this.renderDetailsTable(subheadings, items[i].subItems.items));
      }
    }
    if (isSubitems) {
      return rows;
    } else {
      return (
        <div className="table-responsive">
          <table className="table audit-details">
            <thead>
              <tr>{hdrs}</tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      );
    }
  }

  renderRequestChain(key, chain, t0) {
    const r = chain.request;
    if (t0 === undefined) {
      t0 = r.startTime;
    }
    return (
      <li key={ReactUtils.Key.get(key + chain)}>
        <div>
          [{key}] {r.url} Start: {Formatter.seconds(r.startTime - t0)}, End: {Formatter.seconds(r.endTime - t0)},
          Transfer Size: {Formatter.humanBytes(r.transferSize)}
        </div>
        <ul key={ReactUtils.Key.get(key + chain)}>
          {_.map(chain.children, (c, k) => this.renderRequestChain(k, c, t0))}
        </ul>
      </li>
    );
  }

  renderTreemapNode(node) {
    return (
      <React.Fragment>
        <li>
          <div>
            {node.name}
            Resource: {Formatter.humanBytes(node.resourceBytes)}
            {node.unusedBytes && (
              <React.Fragment>Unused bytes: {Formatter.humanBytes(node.unusedBytes)}</React.Fragment>
            )}
          </div>
          <ul>{_.map(node.children, (n) => this.renderTreemapNode(n))}</ul>
        </li>
      </React.Fragment>
    );
  }

  renderAuditDetails(audit) {
    var detailsBlock = null;
    if (audit.details && audit.details) {
      const details = audit.details;
      if (details.type === 'opportunity' && details.items) {
        const hdrs = _.map(details.headings, (h) => {
          h.type = h.valueType;
          h.text = h.label;
          return h;
        });
        const subhdrs = _.map(details.headings, (h) => {
          if (h.subItemsHeading) {
            return {
              key: h.subItemsHeading.key,
              type: h.subItemsHeading.valueType || h.valueType,
            };
          }
          return null;
        });
        detailsBlock = this.renderDetailsTable(hdrs, details.items, subhdrs);
      } else if (details.type === 'table' && details.items) {
        const hdrs = _.map(details.headings, (h) => {
          h.type = h.valueType || h.itemType;
          h.text = h.label || h.key;
          return h;
        });
        const subhdrs = _.map(details.headings, (h) => {
          if (h.subItemsHeading) {
            return {
              key: h.subItemsHeading.key,
              type: h.subItemsHeading.valueType || h.subItemsHeading.itemType || h.type,
            };
          }
          return null;
        });
        detailsBlock = this.renderDetailsTable(hdrs, details.items, subhdrs);
      } else if (details.type === 'debugdata') {
        /**
         * A details type that is not rendered in the final report; usually used
         * for including debug information in the LHR. Can contain anything.
         */
        return null;
      } else if (details.type === 'criticalrequestchain') {
        const l = details.longestChain;
        detailsBlock = (
          <React.Fragment>
            <div>
              <strong>
                Longest chain: Duration {Formatter.autoDuration(l.duration / 1000)}, length: {l.length}, size:{' '}
                {Formatter.humanBytes(l.transferSize)}
              </strong>
            </div>
            <ul>{_.map(details.chains, (c, k) => this.renderRequestChain(k, c))}</ul>
          </React.Fragment>
        );
      } else if (details.type == 'treemap-data') {
        return <ul>{_.map(details.nodes, (x) => this.renderTreemapNode(x))}</ul>;
      } else if (details.type === 'filmstrip') {
        const frames = _.map(details.items, (i) => {
          return (
            <div key={i.timing} className="filmstrip-frame">
              <img src={i.data} />
              <div className="filmstrip-frame-label">{Formatter.formatMs(i.timing)}</div>
            </div>
          );
        });
        detailsBlock = <div className="filmstrip">{frames}</div>;
      } else if (details.type === 'screenshot') {
        detailsBlock = (
          <div className="screenshot">
            <img src={details.data} />
          </div>
        );
      } else if (details.type === 'list') {
        const items = _.map(details.items, (i) => {
          return <li key={i.text}>{i.text}</li>;
        });
        detailsBlock = <ul>{items}</ul>;
      } else if (details.type === 'text') {
        detailsBlock = <div className="text">{details.text}</div>;
      } else {
        return (
          <div>
            Unsupported Details Type: {details.type}
            <br />
          </div>
        );
      }
    }
    return detailsBlock;
  }

  getColorClass(score) {
    if (score <= 0.49) {
      return 'score-poor';
    } else if (score <= 0.89) {
      return 'score-improve';
    } else {
      return 'score-good';
    }
  }

  renderScoreBadge(audit) {
    const score = audit.score;
    const scoreDisplayMode = audit.scoreDisplayMode || 'numeric';

    let cls = '',
      label = 'n/a';

    if (scoreDisplayMode === 'numeric' || scoreDisplayMode === 'metricSavings') {
      if (score <= 0.49) {
        label = 'Poor';
        cls = 'score-bad';
      } else if (score <= 0.89) {
        label = 'Med';
        cls = 'score-med';
      } else {
        label = 'Good';
        cls = 'score-good';
      }
    } else if (scoreDisplayMode === 'binary') {
      if (score) {
        label = 'Passed';
        cls = 'score-good';
      } else {
        label = 'Failed';
        cls = 'score-bad';
      }
    }
    return <span className={`score-badge ${cls}`}>{label}</span>;
  }

  renderAudit(audit) {
    const key = ReactUtils.Key.get(audit.id);

    let impact = 'low';
    if (audit.weight >= 10) {
      impact = 'med';
    } else if (audit.weight >= 30) {
      impact = 'high';
    } else if (audit.weight == undefined) {
      impact = 'n/a';
    }

    return (
      <div key={audit.id} className="audit p-3">
        <div className="audit-header collapsed" data-toggle="collapse" data-target={`#audit-${key}`}>
          <div className="d-none d-sm-inline-block"> {this.renderScoreBadge(audit)}</div>
          <span className="flex-fill">{audit.title}</span>
          <button className="btn btn-link">
            <i className="fa-regular fa-angle-down d-print-none"></i>
            <i className="fa-regular fa-angle-right d-print-none"></i>
          </button>
        </div>
        <div className="verbose collapse mt-3" id={`audit-${key}`}>
          <div className="audit-descritpion mb-3">{removeLinksFromMarkdown(audit.description)}</div>
          {audit.score !== null && (
            <div className="audit-score mb-2">
              <div className="d-inline-block d-sm-none mr-2">
                <div className="audit-score-badge">{this.renderScoreBadge(audit)}</div>
              </div>
              Score: {audit.score * 100}%
            </div>
          )}
          {audit.displayValue && <div className="audit-display-value mb-3">Result: {audit.displayValue}</div>}
          {this.renderAuditDetails(audit)}
          {audit.impacting && audit.impacting.length > 0 && (
            <div>
              Impact: <span className={`impact-badge impact-${impact}`}>{impact}</span> affecting:{' '}
              {audit.impacting.join(', ')}
              <br />
            </div>
          )}
        </div>
      </div>
    );
  }

  renderPerformanceMetrics(lhr) {
    const metrics = _.sortBy(
      _.filter(lhr.categories.performance.auditRefs, (i) => i.group === 'metrics'),
      (i) => -i.weight
    );

    var items = _.map(metrics, (m) => this.renderMetric(lhr.audits[m.id]));

    return <div className="d-flex audit-metrics-container">{items}</div>;
  }

  renderMetric(audit) {
    const key = ReactUtils.Key.get(audit.id);
    return (
      <div key={key} className="audit p-3 d-flex align-items-center">
        {audit.score !== null && <div className="audit-score-badge mr-3">{this.renderScoreBadge(audit)}</div>}
        <div>
          <div className="label label-sm">
            {audit.title}{' '}
            <i
              data-toggle="tooltip"
              className="fas fa-info-circle d-print-none"
              title={removeLinksFromMarkdown(audit.description)}
            ></i>
          </div>
          <div className="value">{audit.displayValue}</div>
        </div>
      </div>
    );
  }

  renderInformativeMetrics(lhr) {
    if (lhr.audits.metrics === undefined || lhr.audits.metrics.details === undefined) {
      return null;
    }
    const items = lhr.audits.metrics.details.items || [];
    //combine all objects in items in one object

    const data = _.reduce(
      items,
      (result, item) => {
        return _.extend(result, item);
      },
      {}
    );

    const showMetrics = [
      {
        id: 'observedLoad',
        score: null,
        title: 'Observed Load',
        description: 'The time it took to load the page and all of its resources, as observed by the browser.',
        displayValue: Formatter.ms(data.observedLoad),
      },
      {
        id: 'observedDomContentLoaded',
        score: null,
        title: 'Observed DOM Content Loaded',
        description: 'The time it took to load the html page, as observed by the browser.',
        displayValue: Formatter.ms(data.observedDomContentLoaded),
      },
      {
        id: 'observedFirstVisualChange',
        score: null,
        title: 'Observed First Visual Change',
        description: 'The time it took to load the first visual change, as observed by the browser.',
        displayValue: data.observedFirstVisualChange + ' ms',
      },
    ];
    return (
      <div>
        <h3>Informative Metrics</h3>
        <div className="d-flex audit-metrics-container">{showMetrics.map((m) => this.renderMetric(m))}</div>
      </div>
    );
  }

  renderHistoryNotAvailable() {
    if (this.isRunTestReport()) {
      return <div className="py-3">Report History is not available in Run Test mode.</div>;
    } else {
      // if (this.props.query.freetools_test_id)
      if (this.props.userAuthenticated) {
        return (
          <div className="history-placeholder d-print-none">
            <div className="white-block history-cta">
              <div className="circle">
                <i className="fa fa-lock" aria-hidden="true"></i>
              </div>
              <div className="text">Create Pagespeed Check to start collecting metrics for this website.</div>
              <a className="btn btn-primary" href={this.props.createCheckURL}>
                Create Check
              </a>
            </div>
          </div>
        );
      } else {
        return (
          <div className="history-placeholder d-print-none">
            <div className="white-block history-cta">
              <div className="circle">
                <i className="fa fa-lock" aria-hidden="true"></i>
              </div>
              <div className="text">Sign up now to schedule a Pagespeed Check and start collecting metrics data.</div>
              <a className="btn btn-primary" href={this.props.signUpURL}>
                Sign up
              </a>
            </div>
          </div>
        );
      }
    }
  }

  loadPrevHistoryPage(e) {
    e.preventDefault();
    this.setState(
      {
        historyPage: this.state.historyPage - 1,
      },
      () => {
        this.loadReportHistory();
      }
    );
  }

  loadNextHistoryPage(e) {
    e.preventDefault();
    this.setState(
      {
        historyPage: this.state.historyPage + 1,
      },
      () => {
        this.loadReportHistory();
      }
    );
  }

  renderReportHistory() {
    if (this.state.historyLoading) {
      return (
        <div className="py-3">
          <i className="fas fa-spinner fa-spin"></i> Loading history data...
        </div>
      );
    }
    if (!this.state.history || this.state.history.length === 0) {
      return <div className="py-3">No history available.</div>;
    }
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Uptime Grade</th>
              <th>Performance Score</th>
              <th>Guidelines Score</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {this.state.history.map((h) => {
              return (
                <tr key={h.id}>
                  <td>
                    <a href={`?result_id=${h.id}`}>{Formatter.shortDateTime(h.created_at)}</a>
                  </td>
                  <td>{h.uptime_grade}</td>
                  <td>{h.performance_score != null && <span>{Math.round(h.performance_score)}%</span>}</td>
                  <td>{h.best_practices_score != null && <span>{Math.round(h.best_practices_score)}%</span>}</td>
                  <td>{h.monitoring_server.location}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          {this.state.historyHasPrevPage && (
            <a href="#" className="mr-3" onClick={this.loadPrevHistoryPage}>
              Prev page
            </a>
          )}
          {this.state.historyHasNextPage && (
            <a href="#" className="" onClick={this.loadNextHistoryPage}>
              Next page
            </a>
          )}
        </div>
      </div>
    );
  }

  renderAnalysis() {
    if (!this.state.analysis) {
      return null;
    }
    const formatTime = (x) => x.toString() + ' ms';
    const formatBytes = (x) => Math.round(x / 1024.0).toString() + ' KB';
    const formatRequests = (x) => x.toString() + ' requests';
    return (
      <div className="row">
        <DonutChart
          id="loading-per-content-type-chart"
          title="Loading Time per Content Type"
          data={this.state.analysis.loadingPerContentType}
          tooltipFn={formatTime}
        />
        <DonutChart
          id="loading-per-domain-chart"
          title="Loading Time per Domain"
          data={this.state.analysis.loadingPerDomain}
          tooltipFn={formatTime}
          collapseSeries={7}
        />
        <DonutChart
          id="size-per-content-type-chart"
          title="Size per Content Type"
          data={this.state.analysis.sizePerContentType}
          tooltipFn={formatBytes}
        />
        <DonutChart
          id="size-per-domain-chart"
          title="Size per Domain"
          data={this.state.analysis.sizePerDomain}
          tooltipFn={formatBytes}
          collapseSeries={7}
        />
        <DonutChart
          id="requests-per-content-type-chart"
          title="Requests per Content Type"
          data={this.state.analysis.requestsPerContentType}
          tooltipFn={formatRequests}
        />
        <DonutChart
          id="requests-per-domain-chart"
          title="Requests per Domain"
          data={this.state.analysis.requestsPerDomain}
          tooltipFn={formatRequests}
          collapseSeries={7}
        />
      </div>
    );
  }

  renderAnalysisChart(chartId, title) {
    return (
      <div className="col-lg-6 mb-2 analysis-chart" key={chartId}>
        <h4 className="mb-4">{title}</h4>
        <div className="">
          <div className="position-relative">
            <canvas id={chartId} width="0" height="200" style={{width: '100%', height: '200px'}}></canvas>
          </div>
        </div>
      </div>
    );
  }

  calculateAnalysisChartsData() {
    /* calulates data for analysis charts from network data */
    if (!this.state.data || !this.state.data.network) {
      return;
    }
    const analysis = {
      type: {},
      domain: {},
    };
    for (const r of this.state.data.network) {
      const domain = r.url.split('/')[2];
      const resourceType = r.resourceType;
      if (!analysis.domain[domain]) {
        analysis.domain[domain] = {
          time: 0,
          size: 0,
          count: 0,
        };
      }
      if (!analysis.type[resourceType]) {
        analysis.type[resourceType] = {
          time: 0,
          size: 0,
          count: 0,
        };
      }
      let duration = r.timing.loadingFinished - r.timing.requestWillBeSent;
      if (r.fromCache) {
        duration = r.timing.receiveHeadersEnd;
      }

      analysis.domain[domain].time += duration || 0;
      analysis.domain[domain].size += r.size || 0;
      analysis.domain[domain].count += 1;
      analysis.type[resourceType].time += duration || 0;
      analysis.type[resourceType].size += r.size || 0;
      analysis.type[resourceType].count += 1;
    }

    const loadingPerContentType = Object.keys(analysis.type).reduce((acc, x) => {
      acc[x] = Math.round(analysis.type[x].time);
      return acc;
    }, {});

    const loadingPerDomain = Object.keys(analysis.domain).reduce((acc, x) => {
      acc[x] = Math.round(analysis.domain[x].time);
      return acc;
    }, {});

    const sizePerContentType = Object.keys(analysis.type).reduce((acc, x) => {
      acc[x] = Math.round(analysis.type[x].size);
      return acc;
    }, {});

    const sizePerDomain = Object.keys(analysis.domain).reduce((acc, x) => {
      acc[x] = Math.round(analysis.domain[x].size);
      return acc;
    }, {});

    const requestsPerContentType = Object.keys(analysis.type).reduce((acc, x) => {
      acc[x] = Math.round(analysis.type[x].count);
      return acc;
    }, {});

    const requestsPerDomain = Object.keys(analysis.domain).reduce((acc, x) => {
      acc[x] = Math.round(analysis.domain[x].count);
      return acc;
    }, {});

    analysis.loadingPerContentType = loadingPerContentType;
    analysis.loadingPerDomain = loadingPerDomain;
    analysis.sizePerContentType = sizePerContentType;
    analysis.sizePerDomain = sizePerDomain;
    analysis.requestsPerContentType = requestsPerContentType;
    analysis.requestsPerDomain = requestsPerDomain;

    this.setState({
      analysis: analysis,
    });
  }
}
